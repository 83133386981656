// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  attachFile: 'إرفاق ملف',
  clickToCopy: 'انقر للنسخ',
  copied: 'تم النسخ',
  paidPlan: 'الباقة المدفوعة',
  freePlan: 'الباقة المجانية',
  yes: 'نعم',
  no: 'لا',
  from: 'من',
  to: 'إلى',
  dateIncluded: 'يشمل التاريخ',
  search: 'بحث',
  'Load More': 'عرض المزيد',
  cart: {
    paidPlan: 'الباقة المدفوعة',
    freePlan: 'الباقة المجانية',
    features: 'المميزات',
    'Coupon applied successfully': 'تم تطبيق الخصم بنجاح',
    'Invalid coupon code': 'الكوبون غير صحيح',
  },
  weekDays: {
    1: 'الاثنين',
    2: 'الثلاثاء',
    3: 'الأربعاء',
    4: 'الخميس',
    5: 'الجمعة',
    6: 'السبت',
    7: 'الأحد',
  },
  year: 'سنة',
  Jan: 'يناير',
  Feb: 'فبراير',
  Mar: 'مارس',
  Apr: 'أبريل',
  May: 'مايو',
  Jun: 'يونيو',
  Jul: 'يوليو',
  Aug: 'أغسطس',
  Sep: 'سبتمبر',
  Oct: 'أكتوبر',
  Nov: 'نوفمبر',
  Dec: 'ديسمبر',
  loginScreen: {
    mobile_number: 'رقم الجوال',
    password: 'كلمة المرور',
    'The password field is required.': 'يرجى ادخال كلمة المرور',
    'The provided credentials are incorrect.': '.بيانات الدخول غير صحيحة',
    'The mobile number field is required.': 'يرجى ادخال رقم الجوال',
  },
  verifyCodeScreen: {
    'Please provide correct code.': 'يرجى ادخال الرمز الصحيح',
    'Attempts exceeded, request another code.': 'تجاوزت عدد المحاولات، يرجى طلب رمز جديد',
    'You cannot send verification now, try again after 1 minute.':
      'لا يمكنك ارسال رمز التحقق الان، حاول مرة اخرى بعد دقيقة',
  },
  resetPasswordScreen: {
    'After checking the mobile number was not found. Kindly register first.':
      'بعد التحقق، لم يتم العثور على حساب برقم الجوال. يرجى التسجيل أولاً',
  },
  completeAccountScreen: {
    email: 'البريد الإلكتروني',
    name: 'الاسم',
    password: 'كلمة المرور',
    mobile_number: 'رقم الجوال',
    token: 'صلاحية التسجيل',
    'The mobile number has already been taken.': 'الرقم المستخدم مسجل مسبقاً',
    'The name field is required.': 'يرجى ادخال الاسم',
    'The email field is required.': 'يرجى ادخال البريد الإلكتروني',
    'The password field is required.': 'يرجى ادخال كلمة المرور',
    'The name must not be greater than 50 characters.': 'الاسم يجب ان لا يتجاوز 50 حرف',
    'The email must be a valid email address.': 'يرجى ادخال بريد الكتروني صحيح',
    'The email has already been taken.': 'البريد الإلكتروني مسجل مسبقاً',
    'The selected token is invalid.': 'صلاحية التسجيل منتهية، يرجى اعادة خطوات التسجيل',
  },
  addContactScreen: {
    successCreation: 'تم اضافة العميل بنجاح',
    successUpdate: 'تم تعديل معلومات العميل بنجاح',
    'Contact already exists.': 'العميل مسجل مسبقاً',
  },
  navbar: {
    upgradeButton: 'ترقية الحساب 🚀',
    nuzulPayActivation: 'تفعيل 🚀',
  },
  'Save 20% when you pay annually': 'وفر 20% عند الدفع السنوي',
  '/a month': '/شهرياً',
  'Visit your website': 'زيارة موقعك الإلكتروني',
  'Activate your Nuzul Business': 'فعل مكتبك الإلكتروني الآن 🚀',
  'SAR 199': '199 ر.س',
  '/per user a month': '/شهرياً لكل مستخدم',
  'Buy now': 'ابدأ رحلتك الان',
  'Activate your 14-day trial': 'فعل تجربة ال14 يوم!',
  'Please enter a valid number format (e.g., 25000.34)': 'يرجى ادخال رقم صحيح (مثال: 25000.34)',
  Users: 'عدد المستخدمين',
  'Billing period': 'خطة الدفع',
  Monthly: 'شهري',
  Quarterly: 'ربع سنوي',
  Annually: 'سنوي',
  SAR: 'ر.س',
  Subtotal: 'المجموع',
  VAT: 'ضريبة القيمة المضافة',
  Total: 'الإجمالي',
  'Next billing will be': 'الفاتورة القادمة ستكون في',
  'Start now': 'ابدأ الآن',
  'By activating trial, you agree to the': 'عند بداية التجربة انت توافق على ',
  'By proceeding, you agree to the': 'عند الدفع انت توافق على ',
  'Terms and Conditions': 'الشروط والأحكام',
  'Saudi Riyals': 'ريال سعودي',
  'Nuzul Business': 'نزل أعمال',
  'Order Information': 'تفاصيل الطلب',
  'Online payment': 'الدفع الإلكتروني',
  'Bank transfer': 'التحويل البنكي',
  Discount: 'الخصم',
  versionNumber: 'الإصدار',
  hi: 'هلا',
  'Member Insights Dashboard': 'لوحة أداء العضو',
  logout: 'خروج',
  sqm: 'متر مربع',
  meter: 'متر',
  unspecified: 'غير محدد',
  listingsViews: 'عدد الزيارات على الموقع الإلكتروني',
  growth: 'النمو في سنة ',
  clients: 'العملاء',
  total: 'المجموع',
  export_as_sheet: 'تصدير كجدول بيانات',
  export_as_sheet_count: 'تصدير ({{count}}) نتيجة كجدول بيانات',
  Workspaces: 'الحساب',
  'Coming soon!': 'قريباً',
  remove: 'ازالة',
  cancel: 'رجوع',
  removalConfirmation: 'هل أنت متأكد من ازالة هذا التعيين؟',
  for: {
    sell: 'للبيع',
    rent: 'للإيجار',
  },
  auth: {
    login: {
      heading: 'تسجيل الدخول',
      subtitle: 'حياك الله!',
      countryCode: 'رمز الدولة',
      mobileNumber: 'رقم الجوال',
      password: 'كلمة المرور',
      noAccount: 'ليس لديك حساب؟',
      signUp: 'تسجيل',
      loginButton: 'دخول',
      mobileExample: 'مثال: 501234567',
      forgotPassword: 'نسيت كلمة المرور؟',
    },
    completeAccount: {
      subtitle: 'معلومات الحساب',
      name: 'الاسم الكريم',
      email: 'البريد الإلكتروني',
      createAccountBtn: 'انشاء حساب',
      byProceeding: 'عند انشاء الحساب انت تلتزم بـ',
      termsCondition: 'الشروط والاحكام',
    },
    registration: {
      heading: 'فعل تحوّلك الرقمي الآن 🚀',
      subtitle: '',
      alreadyAccount: 'لديك حساب؟',
      login: 'تسجيل الدخول',
      verifyMobile: 'تحقق من رقم الجوال',
    },
    forgotPassword: {
      heading: 'نسيت كلمة المرور؟',
      subtitle: 'الرجاء ادخال رقم الجوال المسجل لدينا.',
    },
    verifyCode: {
      subtitle: 'تم ارسال رمز التحقق الى {{mobileNumber}}',
      verificationCode: 'رمز التحقق',
      resendCode: 'اعادة ارسال رمز التحقق',
      back: 'رجوع',
      verifyCodeBtn: 'تحقق',
      noVerificationCode: 'لم يصلك رمز التحقق؟',
    },
    passwordReset: {
      heading: 'استعادة كلمة المرور',
      subtitle: 'ادخل كلمة المرور الجديدة',
      changePasswordBtn: 'تغيير كلمة المرور',
      success: 'تم تحديث كلمة المرور بنجاح',
    },
  },
  userMenu: {
    yourProfile: 'الملف الشخصي',
    yourWorkspaces: 'الحسابات',
    yourInvitations: 'الدعوات',
  },
  subscription: {
    plans: {},
    checkout: {
      title: 'الدفع',
      subtitle: 'ماهي طريقة الدفع المناسبة؟',
      payOnline: 'ادفع الآن',
      payOffline: 'انشاء طلب',
      applyButton: 'تطبيق',
      couponTitle: 'عندك كوبون خصم؟',
      couponCode: 'رمز الكوبون',
    },
  },
  units: 'الوحدات',
  unit: {
    name_ar: 'اسم الوحدة باللغة العربية',
    name_en: 'اسم الوحدة باللغة الانجليزية',
    translateFromArabic: 'ترجمة بالذكاء الاصطناعي ✨',
    description: 'مميزات الوحدة',
    smartDescription: 'الوصف الذكي',
    descriptionEn: 'ممييزات الوحدة باللغة الانجليزية',
    descriptionAr: 'ممييزات الوحدة باللغة العربية',
    downloadAsPdf: 'تحميل كــPDF',
    createdBy: 'من قبل',
    sort: 'الترتيب في الموقع',
    sortHelperText: 'الرقم الأعلى يظهر أولاً',
    views: 'المشاهدات',
    apartments: 'عدد الوحدات',
    stores: 'عدد المحلات',
    unit_number: 'رقم الوحدة',
    commercial: 'تجاري',
    residential: 'سكني',
    information: 'معلومات الوحدة العقارية',
    createUnitButton: 'إضافة وحدة عقارية',
    archive: 'ارشفة',
    creationTitle: 'معلومات الوحدة العقارية',
    facts: 'المواصفات',
    factsAndFeatures: 'المواصفات والخصائص',
    availabilityStatusAndDownPayment: 'حالة الوحدة والحجز',
    website: 'الموقع الإلكتروني',
    type: 'النوع',
    area: 'المساحة',
    district: 'الحي',
    city: 'المدينة',
    purpose: 'الرغبة',
    rent: 'تأجير',
    sell: 'بيع',
    buy: 'شراء',
    publishedStatus: 'معلومات العرض على الموقع الإلكتروني',
    publishedStatusMustEnablePayment: 'يجب تفعيل إحدى طرق الدفع لعرض الوحدة',
    publishedOnWebsite: 'نشر على الموقع الإلكتروني',
    publishedOnNuzulApp: 'خريطة نُزل (قريباً)',
    paymentMethods: 'طرق الدفع',
    isCashPaymentEnabled: 'تفعيل المدفوعات النقدية',
    isBankTransferPaymentEnabled: 'تفعيل الحوالة البنكية',
    isOnlinePaymentEnabled: 'تفعيل الدفع الإكتروني',
    features: 'الخصائص',
    category: 'نوع العقار',
    listingType: 'المنتج العقاري',
    rentingPrice: 'سعر التأجير',
    monthlyPrice: 'شهرياً',
    quarterlyPrice: 'ربع سنوي',
    semiAnnuallyPrice: 'نصف سنوي',
    annuallyPrice: 'سنوي',
    sellingPrice: 'سعر البيع',
    photos: 'صور الوحدة',
    showPricingRules: 'تخصيص الأسعار',
    showPricingRulesTooltip: 'تحديد أسعار مختلفة لتواريخ معينة: مثلاً: العطلات والأيام السنوية',
    video: 'فيديو',
    building: 'مبنى',
    mansion: 'قصر',
    duplex: 'دبلكس',
    villa: 'فيلا',
    tower_apartment: 'شقة في برج',
    building_apartment: 'شقة في عمارة',
    villa_apartment: 'شقة في فيلا',
    land: 'أرض',
    townhouse: 'تاونهاوس',
    villa_floor: 'دور في فيلا',
    farm: 'مزرعة',
    istraha: 'استراحة',
    store: 'محل تجاري',
    office: 'مكتب',
    storage: 'مخزن',
    hotel: 'فندق',
    resort: 'منتجع',
    compound: 'مجمع سكني',
    showroom: 'معرض',
    streetWidthNorth: 'عرض الشارع الشمالي',
    streetWidthEast: 'عرض الشارع الشرقي',
    streetWidthWest: 'عرض الشارع الغربي',
    streetWidthSouth: 'عرض الشارع الجنوبي',
    facade: 'الواجهة',
    length: 'طول القطعة',
    width: 'عرض القطعة',
    north: 'شمالية',
    east: 'شرقية',
    west: 'غربية',
    south: 'جنوبية',
    northSouth: 'شمالية جنوبية',
    eastWest: 'شرقية غربية',
    northEast: 'شمالية شرقية',
    northWest: 'شمالية غربية',
    southEast: 'جنوبية شرقية',
    southWest: 'جنوبية غريبة',
    northEastWest: 'شمالية شرقية غربية',
    northSouthEast: 'شمالية جنوبية شرقية',
    northSouthWest: 'شمالية جنوبية غربية',
    southEastWest: 'جنوبية شرقية غربية',
    northSouthEastWest: 'شمالية جنوبية شرقية غربية',
    yearBuilt: 'عمر العقار (سنة البناء)',
    owner: 'المالك',
    availability: 'التوافر',
    availabilityStatus: 'حالة الوحدة',
    coverPhoto: 'الصورة الرئيسية',
    coverPhotoFileSize:
      'لضمان سرعة الموقع، الحد الأقصى لحجم الملف هو 500 كيلوبايت، ويجب أن يكون نوع الملف JPG أو PNG أو JPEG.',
    youtubeVideoUrl: 'رابط الفيديو من يوتيوب',
    location: 'تحديد على الخريطة',
    unavailable: 'غير متاحة',
    available: 'متاحة',
    reserved: 'محجوزة',
    sold: 'مباعة',
    rented: 'مؤجرة',
    soon: 'قريباً',
    kingBeds: 'سرير مزدوج',
    singleBeds: 'سرير مفرد',
    numberOfGuests: 'عدد الضيوف',
    numberOfKids: 'عدد الاطفال',
    bedrooms: 'غرفة',
    bathrooms: 'دورة مياة',
    floors: 'الادوار',
    floorNumber: 'رقم الدور',
    dailyLivingRoom: 'الصالة',
    formalLivingRoom: 'صالة الضيوف',
    diningRooms: 'غرفة الطعام',
    maidRooms: 'غرفة الخادمات',
    driverRooms: 'غرفة السائق',
    majlisRooms: 'المجلس',
    storageRooms: 'المخزن',
    basement: 'القبو',
    pool: 'المسبح',
    balconies: 'الشرفة',
    kitchens: 'المطبخ',
    gardens: 'الحديقة',
    mulhaqRooms: 'الملحق',
    elevators: 'المصعد',
    parkingBay: 'موقف سيارة مخصص',
    usageAndServices: 'الاستخدام والخدمات',
    usage: 'الاستخدام',
    services: 'الخدمات',
    electricity: 'الكهرباء',
    water: 'الماء',
    sewage: 'الصرف الصحي',
    farming: 'زراعي',
    health: 'صحي',
    factory: 'صناعي',
    education: 'تعليمي',
    changeOwner: 'تغيير المالك',
    advertiser: {
      title: ' معلومات الاعلان',
      name: 'صفة المعلن',
      owner: 'مالك',
      broker: 'وسيط',
      advertiser: 'معلن',
      licenseNumber: 'رقم الرخصة (من الهيئة العامة للعقار)',
      adNumber: 'رقم الاعلان (من الهيئة العامة للعقار)',
    },
    archiveButton: 'ارشفة',
    unarchiveButton: 'الغاء الارشفة',
    includeArchived: 'تضمين الوحدات المؤرشفة',
    saveButton: 'حفظ',
    plotNumber: 'رقم القطعة',
    planNumber: 'رقم المخطط',
    isWafiAd: 'البيع على الخارطة؟ (وافي)',
    wafiLicenseNumber: 'رقم الترخيص',
    isWhatsAppNumberEnabled: 'تغعيل زر الوتساب',
    isMemberWhatsAppNumberEnabled: 'تفعيل واتساب المسوق (في حال استخدام الرابط الخاص به)',
    whatsappNumber: 'رقم الواتساب',
    whatsappNumberExample: '+966501234567 :مثال',
    clearAll: 'الغاء الكل',
    searchLabel: 'ابحث بالمُعرّف او برقم الوحدة',
    navigateToUnit: 'الانتقال إلى صفحة الوحدة',
    noDeals: 'لا يوجد اي صفقة لهذه الوحدة',
    videoAndVirtualTour: 'الفيديو والجولة الافتارضية',
    price: 'السعر',
    link: 'رابط الوحدة العقارية',
    navToPropertyPage: 'الانتقال الى صفحة الوحدة',
    copyUrl: 'نسخ الرابط',
    copiedUrl: 'تم النسخ',
    media: 'الصور',
    downPaymentOnline: 'حجز الوحدة',
    downPaymentPrice: 'قيمة الحجز',
    downPaymentPriceHelperText: 'زر الدفع الالكتروني يظهر في الموقع فقط عندما تكون حالة الوحدة متاحة',
    downPaymentTaxPercentage: 'نسبة الضريبة على الحجز',
    downPaymentPriceVAT: 'الضريبة على الحجز',
    downPaymentTotalWithVAT: 'المبلغ الإجمالي للحجز',
    downPayment: 'حجز الوحدة',
  },
  listings: 'العروض',
  properties: 'العقارات',
  projects: 'المشاريع',
  project: {
    addProject: 'إضافة مشروع',
    name: 'اسم المشروع',
    information: 'معلومات المشروع',
    nameEn: 'اسم المشروع (English)',
    nameAr: 'اسم المشروع (العربي)',
    descriptionEn: 'وصف المشروع (English)',
    descriptionAr: 'وصف المشروع (العربي)',
    features: 'خصائص المشروع',
    area: 'المساحة',
    plumbingWarranty: 'ضمان السباكة',
    electricWarranty: 'ضمان الكهرباء',
    structuralWarranty: 'ضمان الانشائات',
    numberOfUnites: 'عدد الوحدات',
    numberOfVillas: 'عدد الفلل',
    numberOfBuildings: 'عدد المباني',
    numberOfApartments: 'عدد الشقق',
    numberOfStores: 'عدد المحلات',
    numberOfTownhouse: 'عدد التاونهاوس',
    videoAndPdf: 'فيديو وملف المشروع ',
    projectPdfUrl: 'رابط ملف المشروع',
    availabilityStatus: 'حالة المشروع',
    units: 'وحدات المشروع',
    addUnit: 'اضافة وحدة للمشروع',
    addUnits: 'اضافة وحدات للمشروع',
    add: 'اضافة',
    successCreation: 'تم انشاء المشروع بنجاح',
    successUpdate: 'تم تعديل معلومات المشروع بنجاح',
    archiveButton: 'ارشفة',
    unarchiveButton: 'الغاء الارشفة',
    saveButton: 'حفظ',
    includeArchived: 'تضمين المشاريع المؤرشفة',
    searchLabel: 'ابحث باسم المشروع',
    views: 'المشاهدات',
    district: 'الحي',
    city: 'المدينة',
    publishedOnWebsite: 'نشر على الموقع الإلكتروني',
    standaloneUrl: 'رابط العرض الحصري للمشروع',
    navToProjectPage: 'الانتقال الى صفحة المشروع',
    copyUrl: 'نسخ الرابط',
    copiedUrl: 'تم النسخ',
    project: 'مشروع',
    virtualTourUrl: 'رابط الجولة الافتراضية',
    addUnlimitedProjects: 'اضف عدد لا محدود من المشاريع',
  },
  dashboard: 'لوحة المعلومات',
  membership: 'العضوية',
  deals: 'الصفقات',
  deal: {
    amount: 'قيمة الصفقة  ',
    commission: 'مبلغ العمولة (ريال سعودي)',
    minArea: 'اصغر مساحة',
    maxArea: 'اكبر مساحة',
    minPrice: 'بداية الميزانية',
    maxPrice: 'سقف الميزانية',
    noPropertySelected: 'لم يتم تعيين وحدة',
    building: 'مبنى',
    mansion: 'قصر',
    duplex: 'دبلكس',
    villa: 'فيلا',
    tower_apartment: 'شقة في برج',
    building_apartment: 'شقة في عمارة',
    villa_apartment: 'شقة في فيلا',
    land: 'أرض',
    townhouse: 'تاونهاوس',
    villa_floor: 'دور في فيلا',
    farm: 'مزرعة',
    istraha: 'استراحة',
    store: 'محل تجاري',
    office: 'مكتب',
    storage: 'مخزن',
    hotel: 'فندق',
    resort: 'منتجع',
    compound: 'مجمع سكني',
    showroom: 'معرض',
    facts: 'المواصفات',
    matchedProperty: 'الوحدات المناسبة',
    selectProperty: 'اختر الوحدة',
    searchByUnitNumber: 'ابحث برقم الوحدة',
    filterProperties: 'عرض الوحدات المناسبة فقط ',
    matchedPropertiesInfo:
      'يقوم النظام بترشيح الوحدات المناسبة بناءً على التفضيلات في معلومات الصفقة (شراء او إيجار - سكني او تجاري) ونوع المنتج العقاري والمساحة والحي المرغوب.',
    rentPriceAnnually: 'سعر التأجير السنوي',
    rentPriceSemiAnnually: 'سعر التأجير نصف سنوي',
    rentPriceQuarterly: 'سعر التأجير ربع سنوي',
    rentPriceMonthly: 'سعر التأجير الشهري',
    createDealButton: 'إضافة صفقة',
    searchLabel: 'ابحث بالاسم',
    searchByNames: 'ابحث باسم العميل او المسؤول',
    contactName: 'العميل',
    information: 'معلومات الصفقة',
    preferredLocations: 'الاحياء',
    preferredFeatures: 'الخصائص المفضلة',
    saveButton: 'حفظ',
    clientName: 'اسم العميل',
    clientMobileNumber: 'رقم الجوال',
    listingType: 'المنتج العقاري',
    period: 'المدة',
    daily: 'يومي',
    weekly: 'اسبوعي',
    monthly: 'شهري',
    quarterly: 'ربع سنوي',
    semiAnnually: 'نصف سنوي',
    annually: 'سنوي',
    property: 'العقار',
    stage: 'المرحلة',
    stageDescription: 'تغيير حالة الصفقة',
    city: 'المدينة',
    purpose: 'الرغبة',
    type: 'نوع',
    salesPerson: 'المسؤول',
    creationTitle: 'معلومات الصفقة',
    category: 'نوع العقار',
    sell: 'بيع',
    rent: 'تأجير',
    buy: 'شراء',
    commercial: 'تجاري',
    residential: 'سكني',
    realEstateType: 'المنتج العقاري',
    cancelButton: 'الغاء',
    createButton: 'إضافة',
    changeSalesPerson: 'تغيير المسؤول',
    stages: {
      new: 'صفقة جديدة',
      visit: 'زيارة العقار',
      negotiation: 'المفاوضات',
      won: 'تمت الصفقة',
      lost: 'خسارة الصفقة',
    },
    properties: {
      building: 'مبنى',
      mansion: 'قصر',
      duplex: 'دبلكس',
      villa: 'فيلا',
      tower_apartment: 'شقة في برج',
      building_apartment: 'شقة في عمارة',
      villa_apartment: 'شقة في فيلا',
      land: 'أرض',
      townhouse: 'تاونهاوس',
      villa_floor: 'دور في فيلا',
      farm: 'مزرعة',
      istraha: 'استراحة',
      store: 'محل تجاري',
      office: 'مكتب',
      storage: 'مخزن',
      hotel: 'فندق',
      resort: 'منتجع',
      compound: 'مجمع سكني',
      showroom: 'معرض',
    },
    view: 'مشاهدة',
    archiveButton: 'ارشفة',
    unarchiveButton: 'الغاء الارشفة',
    includeArchived: 'تضمين الصفقات المؤرشفة',
  },
  guests: 'الضيوف',
  guest: {
    includeArchived: 'تضمين الضيوف المؤرشفين',
    addGuest: 'إضافة ضيف',
    information: 'معلومات الضيف',
    createPageTitle: 'إضافة ضيف',
    createButton: 'إضافة ضيف',
    editGuest: 'تعديل',
    saveButton: 'حفظ',
  },
  contacts: 'العملاء',
  contact: {
    createdBy: 'من قبل',
    searchLabel: 'ابحث بالاسم',
    searchByNumLabel: 'ابحث بالاسم او برقم الجوال',
    addContact: 'إضافة عميل',
    information: 'معلومات العميل',
    typeSelect: 'اختر المناسب',
    createPageTitle: 'إضافة عميل',
    name: 'الاسم الكريم',
    mobileNumber: 'رقم الجوال',
    createButton: 'إضافة عميل',
    saveButton: 'حفظ',
    archiveButton: 'ارشفة',
    unarchiveButton: 'الغاء الارشفة',
    includeArchived: 'تضمين العملاء المؤرشفين',
    type: 'نوع العميل',
    property_buyer: 'مشتري',
    property_renter: 'مستأجر',
    property_owner: 'مالك عقار',
    property_agent: 'وسيط',
    property_broker: 'مكتب',
    property_representative: 'وكيل',
    email: 'البريد الإلكتروني (اختياري)',
    country: 'الدولة (اختياري)',
    city: 'المدينة (اختياري)',
    gender: 'الجنس',
    male: 'ذكر',
    female: 'أنثى',
    editContact: 'تعديل',
    note: 'ملاحظات',
    ownedProperties: 'العقارات المملوكة',
    navigateToContact: 'الانتقال إلى صفحة العميل',
    noDeals: 'لا توجد أي صفقة لهذا العميل',
    noOwnedProperties: 'لا توجد أي عقارات مملوكة من قبل هذا العميل',
    noPropertyRequests: 'لا توجد أي طلبات عقار من قبل هذا العميل',
    noReservations: 'لا توجد أي حجوزات من قبل هذا العميل',
  },
  workspaces: 'الحسابات',
  workspaceTitle: 'الحساب',
  workspace: {
    leave: 'مغادرة الحساب',
    default: 'اساسي',
    setDefault: 'تعيين مساحة عمل اساسية',
    settings: 'إعدادات',
    confirmLeave: 'تأكيد المغادرة',
    generalInformation: 'المعلومات العامة',
    subscriptions: 'الاشتراكات',
    currentPackage: 'الباقة الحالية',
    daysLeftOnSubscription: 'ايام متبقية للاشتراك',
    paymentHistory: 'العمليات السابقة',
    companyLogo: 'الشعار',
    uploadLogo: 'رفع الشعار',
    save: 'حفظ',
    nameEn: 'اسم الحساب (English)',
    nameAr: 'اسم الحساب (العربي)',
    plans: 'خطة الدفع',
    currentPlan: 'خطة الدفع الحالية',
    daysLeftOnPlan: 'الايام متبقية على الخطة الحالية',
    numberOfUsers: 'عدد المستخدمين',
    table: {
      status: 'الحالة',
      period: 'المفترة',
      amount: 'الاجمالي',
      orderDate: 'تاريخ الطلب',
      package: 'الباقة',
      startDate: 'تاريخ البداية',
      endDate: 'تاريخ النهاية',
      active: 'فعال',
      completed: 'مكتمل',
      canceled: 'ملغي',
      pending_payment: 'في انتظار الدفع',
      expired: 'منتهي',
      yearly: 'سنوي',
      quarterly: 'ربع سنوي',
      type: ' الفترة',
      one_time: '-',
      subscription_trial: ' التجريبي',
      subscription_quarterly: ' ربع سنوي',
      subscription_yearly: ' سنوي',
      subscription_monthly: ' شهري',
      total_amount_with_tax: 'المجموع شامل الضريبة',
    },
    day: ' يوم',
  },
  invitations: 'الدعوات',
  invitation: {
    accepted: 'مقبولة',
    declined: 'مرفوضة',
    canceled: 'ملغية',
    expired: 'منهية الصلاحية',
    pending: 'في انتظار الرد',
    accept: 'قبول',
    decline: 'رفض',
    dialog: {
      cancel: 'الغاء',
      heading: 'هل أنت متأكد؟',
      descriptionAccept: 'بقبول دعوة، سيكون لديك حق الوصول إلى أية معلومات متعلقة بمساحة العمل.',
      descriptionDecline: 'برفض دعوة، لن تتمكن من الوصول إلى أي معلومات متعلقة بمساحة العمل.',
    },
    //
    //
  },
  member: {
    inviteMember: 'دعوة عضو',
    members: 'الاعضاء',
    invitations: 'الدعوات',
    inviteTitle: 'الى من ترسل الدعوة؟',
    invite: 'دعوة',
    cancel: 'الغاء',
    removeMemberConfirmation: 'هل انت متاكد من ازالة هذا العضو؟',
    successRemoveMember: 'تم ازالة العضو بنجاح',
    failureRemoveMember: 'حدث خطأ اثناء ازالة العضو',
    edit: 'تغيير',
    successEditMember: 'تم تعديل الصلاحية بنجاح',
    failureEditMember: 'حدث خطأ اثناء تعديل الصلاحية',
    memberLink: 'رابط العضو في الموقع الإلكتروني',
    card: 'عرض بطاقة المسوق',
    saveButton: 'حفظ',
    editURLSuccessfully: 'تم تعديل الرابط بنجاح',
  },
  team: 'الفريق',
  teams: {
    mobileNumber: 'رقم الجوال',
    name: 'الاسم',
    role: 'الصلاحية',
    dateJoined: 'تاريخ الانضمام',
    dateSent: 'تاريخ الدعوة',
    roleChange: 'تغيير الصلاحية',
    remove: 'ازالة',
    removeAll: 'حذف العضو بشكل نهائي',
    removeAllInfo: 'سيتم حذف العضو بشكل نهائي من جميع الصفقات والطلبات والعملاء والعقارات.',
    status: 'الحالة',
    pending: 'في انتظار القبول',
    declined: 'مرفوضة',
    canceled: 'ملغية',
    accepted: 'مقبولة',
    managerRole: 'مدير',
    associateRole: 'مساعد',
    memberRole: 'عضو',
    ownerRole: 'مالك',
    inviteScreen: {
      successCreation: 'تم ارسال الدعوة بنجاح',
      'There is a already pending invitation.': 'تم دعوة العضو مسبقاً',
      'The user is already a member.': 'العضو موجود مسبقاً',
    },
  },
  general: 'عام',
  CRM: 'علاقات العملاء',
  GRM: 'علاقات الضيوف',
  'listings & services': 'العروض والخدمات',
  Financials: 'المالية',
  Operations: 'العمليات',
  management: 'إدارة',
  website: 'الموقع الإلكتروني',
  marketing: 'التسويق',
  requests: 'طلبات العقار',
  request: {
    editPageTitle: 'تفاصيل طلب عقار',
    addService: 'إضافة طلب عقار',
    contactName: 'اسم العميل',
    new: 'جديد',
    converted: 'محولة الى صفقة',
    closed: 'مغلق',
    status: 'الحالة',
    facts: 'المعلومات الاساسية',
    convertToDeal: 'تحويل الى صفقة',
    confirmConvertToDeal: 'تأكيد التحويل الى صفقة',
    confirmCloseRequest: 'تأكيد اغلاق الطلب',
    cancelButton: 'رجوع',
    closeButton: 'اغلاق الطلب',
    message: 'رسالة العميل',
    statusTitle: 'حالة الطلب',
    closingReason: 'سبب الاغلاق',
    closedBy: 'تم الاغلاق بواسطة',
    convertedBy: 'تم التحويل الي صفقة بواسطة',
    closeRequestConfirmation: 'هل انت متأكد من اغلاق الطلب؟',
    convertToDealRequestConfirmation: 'هل انت متأكد من تحويل الطلب الى صفقة؟',
    closedSuccessfully: 'تم اغلاق الطلب بنجاح',
    convertedSuccessfully: 'تم تحويل الطلب الى صفقة بنجاح',
  },
  orders: 'الطلبات',
  order: {
    customer: 'اسم العميل',
    status: 'الحالة',
    totalAmount: 'المبلغ الإجمالي',
    orderDate: 'تاريخ الطلب',
    down_payment: 'حجز وحدة',
    type: 'نوع و وصف الطلب',
    pending_payment: 'في انتظار الدفع',
    completed: 'مكتمل',
    canceled: 'ملغي',
    editPageTitle: 'تفاصيل الطلب',
    propertyOrder: 'الوحدة المحجوزة',
    paymentMethod: 'وسيلة الدفع',
    paymentMethods: {
      cash: 'نقدي',
      bankTransfer: 'حوالة بنكية',
      online: 'دفع إلكتروني',
    },
    downloadInvoice: 'تحميل الفاتورة',
  },
  sales: 'المبيعات',
  services: 'الخدمات',
  service: {
    createPageTitle: 'اضافة خدمة',
    editPageTitle: 'تعديل خدمة',
    createButton: 'اضافة خدمة',
    saveButton: 'حفظ',
    addService: 'اضافة خدمة',
    nameAr: 'اسم الخدمة (بالعربية)',
    descriptionAr: 'وصف الخدمة (بالعربية)',
    nameEn: 'اسم الخدمة (بالإنجليزية)',
    descriptionEn: 'وصف الخدمة (بالإنجليزية)',
    informationEn: 'معلومات الخدمة بالإنجليزية',
    informationAr: 'معلومات الخدمة بالعربية',
    price: 'السعر (ريال)',
    publishedStatus: 'معلومات النشر',
    publishedOnWebsite: 'نشر على الموقع الإلكتروني',
    pricing: 'التسعير',
    basePrice: 'السعر الأساسي',
    taxPercentage: 'نسبة الضريبة',
    isFree: 'خدمة مجانية',
    successCreation: 'تم اضافة الخدمة بنجاح',
    successUpdate: 'تم تعديل الخدمة بنجاح',
  },
  'SMS Campaigns': 'حملات الرسائل النصية (قريباً)',
  web: {
    domain: {
      title: 'رابط الموقع الإلكتروني',
      subtitle: 'تعديل معلومات النطاق (Domain)',
      information: 'معلومات النطاق',
      access: 'يمكنك الوصول الى موقعك الإلكتروني الخاص عبر الرابط:',
      contact: 'تواصل معنا لتعديل الرابط او ربطه بدومين خاص حسب الخيارات المتاحة ادناه',
      option1: 'الخيار الاول:',
      option2: 'الخيار الثاني:',
      email: 'البريد الإلكتروني',
      whatsapp: 'رقم الوتساب',
    },
    socialMediaLinks: {
      title: 'روابط التواصل الاجتماعي',
      subtitle: 'تعديل معلومات حسابات التواصل الاجتماعي',
      x: 'منصة اكس',
      instagram: 'انستقرام',
      snapchat: 'سناب شات',
      tiktok: 'تيك توك',
      email: 'البريد الإلكتروني',
      whatsapp: 'رقم الوتساب',
      phoneNumber: 'رقم استلام المكالمات',
      whatsappFloatingButton: 'تفعيل زر الوتساب',
      gTagRadio: 'تفعيل Google Tag Manager',
      googleTagManager: 'Google Tag Manager measurement ID رقم التعريف',
      metaPixel: 'Facebook Pixel رقم تعريف',
      metaPixelRadio: 'تفعيل Facebook Pixel',
      snapchatPixel: 'Snapchat Pixel رقم تعريف',
      snapchatPixelRadio: 'تفعيل Snapchat Pixel',
    },
    licenses: {
      title: 'الشهادات والتراخيص الحكومية',
      motIwaaNumber: 'رقم تصريح ايواء',
      motIwaaNumberDescription: 'جهة الاصدار: وزارة السياحة',
      regaFal: 'رقم رخصة فال',
      regaFalDescription: 'جهة الاصدار: الهيئة العامة للعقار',
      mcCrNumber: 'رقم السجل التجاري',
      mcCrNumberDescription: 'جهة الاصدار: وزارة التجارة',
      zatcaCertificateNumber: 'رقم الشهادة الضريبية',
      zatcaCertificateNumberDescription: 'جهة الاصدار: هيئة الزكاة والضريبة والجمارك',
      hrsdFreelanceNumber: 'رقم وثيقة العمل الحر',
      hrsdFreelanceNumberDescription: 'جهة الاصدار: وزارة الموارد البشرية والتنمية الاجتماعية',
    },
    integrationTitle: 'الربط',
    integrationInformation: 'الربط مع مواقع التواصل الاجتماعي',
    identity: {
      title: 'الهوية',
      subtitle: 'تعديل الشعار و الالوان',
      logo: 'الشعار',
      colors: 'الالوان',
      primaryColor: 'اللون الاساسي',
      secondaryColor: 'اللون الثانوي',
      exampleButton: 'هذا زر اساسي',
      exampleButtonSecondary: 'هذا زر ثانوي',
      exampleButtonTertiary: 'هذا رابط',
      darkTheme: 'النمط الداكن',
      enableDarkTheme: 'تفعيل النمط الداكن',
    },
    pages: {
      title: 'الصفحات',
      subtitle: 'تعديل صفحات موقعك الإلكتروني',
      saveButton: 'حفظ',
      terms: {
        title: 'الشروط والاحكام',
        subtitle: 'تعديل صفحة الشروط والاحكام لموقعك الإلكتروني',
        activationTitle: 'التفعيل',
        edit: 'تعديل الشروط والاحكام',
        termsEn: 'الشروط والاحكام (English)',
        termsAr: 'الشروط والاحكام (العربية)',
        successUpdate: 'تم تعديل الشروط والاحكام',
      },
      privacy: {
        title: 'سياسة الخصوصية',
        subtitle: 'تعديل صفحة سياسة الخصوصية لموقعك الإلكتروني',
        activationTitle: 'التفعيل',
        edit: 'تعديل سياسة الخصوصية',
        privacyEn: 'سياسة الخصوصية (English)',
        privacyAr: 'سياسة الخصوصية (العربية)',
        successUpdate: 'تم تعديل سياسة الخصوصية',
      },
    },
    map: {
      title: 'الخريطة',
      subtitle: 'تعديل اعدادات الخريطة',
      activationTitle: 'التفعيل',
      activationDescription: 'تفعيل الخريطة',
      saveButton: 'حفظ',
    },
  },
  settings: 'الاعدادات',
  setting: {
    accountInformationTitle: 'معلومات الحساب',
    accountInformation: 'تعديل اسم الحساب و الشعار.',
    accountSubscriptionTitle: 'الاشتراكات',
    accountSubscription: 'تفاصيل الاشتراكات و الفواتير.',
    propertyRequestsTitle: 'طلبات العقار',
    propertyRequests: 'تعديل اعدادت طلبات العقار.',
    propertyRequest: {
      title: 'اعدادت طلبات العقار',
      activationTitle: 'التفعيل',
      publish: 'نشر',
      activationDescription: 'تفعيل طلب العقار',
      activationListingDescription: 'تفعيل طلبات الاعلان على الموقع الإلكتروني',
      editPageTitle: 'تعديل طلب العقار',
      createButton: 'إضافة طلب عقار',
      saveButton: 'حفظ',
      addRequest: 'إضافة طلب عقار',
      configurations: 'اعدادات الطلب',
      category: 'انواع العقار المسموحة للطلب',
      commercial: 'تجاري',
      residential: 'سكني',
      purposes: 'الرغبات المسموحة',
      rent: 'ايجار',
      buy: 'شراء',
      allowedPropertyTypes: 'المنتجات العقارية المسموحة حسب نوع العقار',
      propertyTypes: 'المنتجات العقارية',
      propertyTypesHelper: 'يمكن اختيار اكثر من منتج عقاري',
      allowedLocations: 'الاحياء المسموحة',
      districtsHelper: 'يمكن اختيار اكثر من حي',
      successUpdate: 'تم تعديل الاعدادات بنجاح',
      failedUpdate: 'حدث خطأ اثناء تعديل الاعدادات',
    },
    paymentSettingsTitle: 'المدفوعات',
    paymentSettings: 'تعديل الحسابات البنكية والرقم الضريبي واعدادات الدفع.',
    paymentSetting: {
      title: 'اعدادات المدفوعات',
      paymentMethods: 'تفعيل طرق الدفع',
      bankTransfer: 'تحويل بنكي',
      cash: 'نقدي',
      onlinePayment: 'الدفع الإلكتروني',
      bankAccounts: 'الحسابات البنكية',
      addBankAccount: 'إضافة حساب بنكي',
      bank: 'البنك',
      accountNumber: 'رقم الحساب',
      iban: 'الايبان',
      beneficiaryNameAr: 'اسم المستفيد (بالعربية)',
      beneficiaryNameEn: 'اسم المستفيد (بالانجليزية)',
      beneficiaryName: 'اسم المستفيد',
      swift: 'السويفت (اختياري)',
      createButton: 'إضافة الحساب',
      cancelButton: 'الغاء',
      requiresNuzulPay: 'يتطلب تفعيل نزل Pay',
    },
  },
  user: {
    profile: 'الملف الشخصي',
    personalInformation: 'المعلومات الشخصية',
    name: 'الاسم',
    email: 'البريد الإلكتروني',
    mobileNumber: 'رقم الجوال',
    gender: 'الجنس',
    male: 'ذكر',
    female: 'أنثى',
    saveButton: 'حفظ',
  },
  financial: 'المالية',
  transactions: 'العمليات',
  transaction: {},
  deposits: 'الايداعات',
  pay: {
    salesGrowth: 'نمو المبيعات خلال',
    transaction: {
      orderNumber: 'رقم الطلب',
      client: 'العميل',
      amount: 'المبلغ',
      status: 'الحالة',
      date: 'التاريخ',
    },
    deposit: {
      day: 'اليوم',
      dateTime: 'التاريخ',
      total: 'المجموع',
      fee: 'رسوم الايداع',
      bank: 'البنك',
    },
    activation: {
      title: 'تغعيل نزل Pay',
      rates: 'الاسعار',
      applePayRate: 'بناء على نوع البطاقة المستخدمة',
      vat: 'غير شامل ضريبة القيمة المضافة',
      requirements: 'المتطلبات',
      individual: 'الافراد',
      institutionCompany: 'المؤسسات والشركات',
      ownerId: 'هوية المالك',
      freelanceCert: 'وثيقة العمال الحر',
      faalCert: 'للخدمات العقارية رخصة فال من هيئة العقار',
      motIwaaCert: 'للخدمات السياحية رخصة ايواء من وزارة السياحة',
      nationalAddress: 'العنوان الوطني',
      process: 'طلب التفعيل',
      contactMessage: 'ارسال رسالة على البريد الإلكتروني بالمستندات المطلوبة',
      bankCert: 'الشهادة البنكية',
      OwnersIds: 'هوية الملاك واعضاء مجلس الادارة',
      crCert: 'وثيقة السجل التجاري',
      aoc: 'عقد التأسيس',
      uploadDocuments: 'رفع المستندات',
    },
  },
  contracts: 'العقود (قريباً)',
  District: 'الحي',
  Districts: 'الاحياء',
  City: 'المدينة',
  Country: 'الدولة',
  Success: '',
  'Edited stage successfully': 'تم تعديل مرحلة الصفقة',
  'Successfully updated': 'تم التعديل بنجاح',
  'Upload a logo': 'رفع الشعار',
  'For best resolution and ratio use 500px X 500px as minimum 1:1 ratio. Only PNG and JPG files are supported.':
    'لجودة متناهية ونسبة مثالية، استخدم 500 بيكسل * 500 بيكسل كحد أدنى للنسبة 1:1. بإمكانك رفع ملفات PNG و JPG فقط.',
  'You have reached the limit of properties for your current plan.':
    'لقد وصلت الى الحد الاقصى للوحدات في الخطة الحالية',
  siyaha: {
    listingsAndReservations: 'الوحدات والحجوزات',
    units: 'الوحدات',
    reservations: 'الحجوزات',
    numberOfReservations: 'عدد الحجوزات',
    numberOfUnits: 'عدد الوحدات',
    reservation: {
      cancelButton: 'الغاء الحجز',
      confirmButton: 'تأكيد الحجز',
      confirmPaymentButton: 'تأكيد الدفع',
      confirmSuccess: 'تم تأكيد الحجز بنجاح',
      cancelSuccess: 'تم الغاء الحجز بنجاح',
      confirmPayment: 'تأكيد الدفع واصدار الفاتورة',
      propertyInformation: 'معلومات الوحدة السياحية',
      guestName: 'اسم الضيف',
      guestMobileNumber: 'رقم الجوال',
      guestEmail: 'البريد الإلكتروني',
      unitNumber: 'رقم الوحدة',
      status: 'حالة الحجز',
      paymentStatus: 'حالة الدفع',
      reservationDetails: 'تفاصيل الحجز',
      dates: 'التواريخ',
      checkInDate: 'تاريخ الوصول',
      checkOutDate: 'تاريخ المغادرة',
      numberOfNights: 'عدد الليالي',
      guestsInformation: 'معلومات الضيوف',
      dateOfBirth: 'تاريخ الميلاد',
      statues: {
        on_hold: 'في انتظار التأكيد',
        pending: 'في انتظار الدفع',
        confirmed: 'مؤكد',
        canceled: 'ملغي',
      },
      paymentStatuses: {
        pending_payment: 'في انتظار الدفع',
        paid: 'تم الدفع',
        canceled: 'ملغي',
        completed: 'مكتمل',
      },
      paymentMethod: 'وسيلة الدفع',
      paymentMethods: {
        cash: 'نقدي',
        bankTransfer: 'حوالة بنكية',
        online: 'دفع إلكتروني',
      },
      calendar: 'التقويم',
    },
    unit: {
      createUnitButton: 'إضافة وحدة سياحية',
      creationTitle: 'معلومات الوحدة السياحية',
      dailyRatePrice: 'سعر التأجير اليومي',
      dailyRateWeekendPrice: 'سعر التأجير أيام نهاية الأسبوع',
    },
  },
  addListingsModal: {
    trialMessage: 'اضف عدد لا محدود من الوحدات',
  },
  notes: {
    notes: 'الملاحظات',
    activitiesAndComments: 'الكل',
    activities: 'سجل التغييرات',
    notesDescription: 'تتم مشاركة الملاحظات داخليًّا فقط بين الفريق.',
    logNoteButton: 'إرسال الملاحظة',
    noteSent: 'تم إرسال الملاحظة بنجاح',
    deleteNote: 'حذف الملاحظة',
    deleteNoteConfirmation: 'هل أنت متأكد من حذف الملاحظة؟',
    noteDeleted: 'تم حذف الملاحظة بنجاح',
    editNote: 'تعديل الملاحظة',
    editSave: 'حفظ التعديل',
    cancelButton: 'الغاء',
    noteUpdated: 'تم تعديل الملاحظة بنجاح',
    noteEdited: 'تم التعديل',
  },
  'View All': 'عرض الكل',
  Notifications: 'التنبيهات',
  notifications: {
    deleteSuccess: 'تم حذف التنبيه بنجاح',
    delete: 'حذف',
    deleteAllConfirmation: 'ستُحذَف جميع التنبيهات، هل أنت متأكد؟',
    deleteAll: 'حذف الكل',
    empty: 'لا توجد تنبيهات جديدة. كل شيء جاهز!',
    unread: 'لديك {{count}} تنبيه غير مقروء',
    markAllAsRead: 'تحديد الكل كمقروء',
    markAsRead: 'تحديد كمقروء',
    markAsUnread: 'تحديد كغير مقروء',
    noteTitle: '📝 ملاحظة',
    note: '[{{content}}]، من قبل: [{{userName}}]',
    newClientDealTitle: '🏡 صفقة جديدة',
    newClientDeal: 'العميل [{{clientName}}] يرغب في [{{propertyType}}]',
    newClientReservationTitle: '🏡 حجز جديد',
    newClientReservation: 'العميل [{{clientName}}] يرغب في حجز [{{propertyType}}]',
    dealAssignedTitle: '📋 تعيين صفقة',
    dealAssigned: 'العميل [{{clientName}}] للعقار [{{propertyType}}]، تعيين لك من قبل [{{assignedByName}}]',
    newPropertyRequestTitle: '🏠 طلب عقار جديد',
    newPropertyRequest: 'العميل [{{clientName}}] يرغب في [{{propertyType}}]',
    tenantInvitationTitle: '✉️ دعوة انضمام',
    tenantInvitation: 'تمت دعوتك للانضمام إلى فريق [{{workspaceName}}]',
    invitationAcceptedTitle: '✅ تم قبول الدعوة',
    invitationAccepted: 'المستخدم [{{userName}}] قبل دعوتك للانضمام إلى فريق [{{workspaceName}}]',
  },
  createdAt: 'تاريخ الإنشاء',
  platformFeatures: 'ميزات المنصة',
  websiteFeatures: 'ميزات الموقع الإلكتروني',
  APIFeatures: 'ميزات واجهة برمجة التطبيقات',
  bestSeller: 'الاكثر طلباً',
  soon: 'قريباً',
  plans: {
    basic: 'الأساسية',
    pro: 'الإنطلاقة',
    enterprise: 'الإحترافية',
  },
  siyahaFeatures: {
    platform: {
      users: 'عدد لامحدود من المستخدمين',
      roles: 'تحديد صلاحيات المستخدمين',
      properties: 'عدد لامحدود من العقارات',
      bookings: 'عدد لامحدود من الحجوزات',
      requests: 'عدد لامحدود من الطلبات',
      typesOfProperties: 'دعم كل أنواع العقارات',
      language: 'دعم اللغتين العربية والإنجليزية',
      payments: 'تفعيل الدفع الإلكتروني',
      whatsapp: 'تفعيل الواتساب',
      exportData: 'تصدير البيانات إلى جدول (أكسل)',
      propertyRequests: 'تفعيل الطلبات العقارية',
    },
    website: {
      customDomain: 'حجز رابط خاص (دومين)',
      nuzulDomain: 'رابط (دومين) نزل xxx.nuzul.app',
      specialWebsiteUI: 'موقع الكتروني وواجه خاصة',
    },
    API: {
      chatApps: 'الربط مع تطبيقات المحادثة',
      almosaferAPI: 'الربط مع المسافر',
      bookingAPI: 'الربط مع Booking',
      airbnbAPI: 'الربط مع Airb&b',
      smsApps: 'الربط مع تطبيقات الرسائل النصية',
      marketingApps: 'الربط مع التطبيقات التسويقية',
      accountingApps: 'الربط مع التطبيقات المحاسبية',
    },
    other: {
      buildingApps: 'إنشاء تطبيق iOS و Android * شراء التطبيق بقيمة إضافية',
    },
  },
  maktbFeatures: {
    platform: {
      users: 'عدد لامحدود من المستخدمين',
      roles: 'تحديد صلاحيات المستخدمين',
      properties: 'عدد لامحدود من العقارات',
      deals: 'عدد لامحدود من الصفقات',
      requests: 'عدد لامحدود من الطلبات',
      typesOfProperties: 'دعم كل أنواع العقارات',
      language: 'دعم اللغتين العربية والإنجليزية',
      payments: 'تفعيل الدفع الإلكتروني',
      whatsapp: 'تفعيل الواتساب',
      exportData: 'تصدير البيانات إلى جدول (أكسل)',
      propertyRequests: 'تفعيل الطلبات العقارية',
    },
    website: {
      customDomain: 'حجز رابط خاص (دومين)',
      nuzulDomain: 'رابط (دومين) نزل xxx.nuzul.app',
      specialWebsiteUI: 'موقع الكتروني وواجه خاصة',
    },
    API: {
      chatApps: 'الربط مع تطبيقات المحادثة',
      smsApps: 'الربط مع تطبيقات الرسائل النصية',
      marketingApps: 'الربط مع التطبيقات التسويقية',
      accountingApps: 'الربط مع التطبيقات المحاسبية',
    },
    other: {
      buildingApps: 'إنشاء تطبيق iOS و Android * شراء التطبيق بقيمة إضافية',
    },
  },
  land: 'أرض',
  tower_apartment: 'شقة في برج',
  commercial: 'تجاري',
  residential: 'سكني',
  rent: 'للتأجير',
  sell: 'للبيع',
  number: 'رقم #',
};

export default ar;
