// @mui
import { Box, Button, Card, CardContent, Checkbox, Container, FormControlLabel, Grid, Typography } from '@mui/material';
// hooks
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import * as userActions from '../../../models/user/actions';
import * as workspaceActions from '../../../models/workspace/actions';
import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';

// ----------------------------------------------------------------------

export default function MapSettings() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const workspaceInformationData = useSelector(workspaceSelectors.workspaceInformationData);
  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaceInformationRequest());
    dispatch(userActions.getUserInformationRequest());
  }, []);

  const callback = (type) => {
    if (type === 'success') {
      toast('Success!', { type: 'success' });
      dispatch(workspaceActions.getWorkspaceInformationRequest());
      dispatch(userActions.getUserWorkspacesRequest());
      dispatch(userActions.getUserInformationRequest());
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  const formik = useFormik({
    onSubmit: (values) => {
      dispatch(workspaceActions.editWorkspaceInformationRequest({ ...values, callback }));
    },
    initialValues: {
      is_map_enabled: !!workspaceInformationData?.is_map_enabled || false,
    },
    enableReinitialize: true,
  });

  return (
    <Page title="Map">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('web.map.title')}
            </Typography>
          </Box>
          {!activeMaktbSubscription?.is_trial && (
            <Button variant="contained" sx={{ color: 'white', pt: 1, pb: 1 }} onClick={() => formik.handleSubmit()}>
              {translate('web.map.saveButton')}
            </Button>
          )}
        </Box>

        <Card>
          <CardContent>
            <Typography variant="h6" paragraph>
              {translate('web.map.activationTitle')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  label={translate('web.map.activationDescription')}
                  control={
                    <Checkbox
                      name="is_map_enabled"
                      checked={formik.values.is_map_enabled}
                      disabled={activeMaktbSubscription?.is_trial}
                    />
                  }
                  onChange={formik.handleChange}
                />
                {activeMaktbSubscription?.is_trial && (
                  <span
                    style={{
                      backgroundColor: '#ddd',
                      marginLeft: 7,
                      marginRight: 7,
                      paddingRight: 7,
                      paddingLeft: 7,
                      paddingTop: 3,
                      paddingBottom: 3,
                      borderRadius: 5,
                    }}
                  >
                    {translate('paidPlan')}
                  </span>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
