// @mui
import { Box, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthButton from '../../../components/buttons/AuthButton';
import { Loader } from '../../../components/Loader';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import AuthBaseLayout from '../../../layouts/AuthBaseLayout';
import * as authActions from '../../../models/auth/actions';
import * as userActions from '../../../models/user/actions';
// store
import { checkEmailIsValid } from '../../../utils/validation';
import { disableButton } from '../Auth.utils';
import { completeAccountSchema } from '../Auth.validationSchemas';
import { Password } from '../components';

const CompleteAccount = () => {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  const token = state?.token;

  const handleNavigate = (type, res) => {
    setLoading(false);

    if (type === 'success') {
      window.open('/', '_self');
    }
  };

  if (!token) {
    window.open('/login', '_self');
  }

  const handleCallback = (type, res) => {
    if (type === 'success') {
      dispatch(userActions.getUserInformationRequest({ callback: handleNavigate }));
    } else {
      setLoading(false);

      if (res.status === 422) {
        const validationErrors = res.data.errors;
        const errorMessages = Object.entries(validationErrors).map(
          ([field, messages]) =>
            `${translate(`completeAccountScreen.${field}`)}: ${translate(`completeAccountScreen.${messages}`)}`
        );
        errorMessages.forEach((errorMessage) => {
          toast.error(errorMessage);
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },

    validate: (values) => {
      const errors = {};

      if (!values.email) {
        errors.email = translate(`completeAccountScreen.The email field is required.`);
      } else if (!checkEmailIsValid(values.email)) {
        errors.email = translate(`completeAccountScreen.The email must be a valid email address.`);
      }

      if (!values.name) {
        errors.name = translate(`completeAccountScreen.The name field is required.`);
      }

      if (!values.password) {
        errors.password = translate(`completeAccountScreen.The password field is required.`);
      }

      return errors;
    },
    completeAccountSchema,
    onSubmit: (values) => {
      const { name, email, password } = values;
      setLoading(true);
      dispatch(authActions.createAccountRequest({ token, name, email, password, callback: handleCallback }));
    },
  });

  const isButtonDisabled = disableButton(['name', 'email', 'password'], formik) || loading;

  return (
    <AuthBaseLayout
      heading={translate('auth.registration.heading')}
      subtitle={translate('auth.completeAccount.subtitle')}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="outlined-basic"
          name="name"
          label={translate('auth.completeAccount.name')}
          variant="outlined"
          sx={{ marginTop: '30px' }}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          fullWidth
          id="outlined-basic"
          name="email"
          label={translate('auth.completeAccount.email')}
          variant="outlined"
          sx={{ marginTop: '10px' }}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          style={{ direction: 'ltr' }}
        />

        <Password formik={formik} />

        <AuthButton text={translate('auth.completeAccount.createAccountBtn')} isDisabled={isButtonDisabled} />

        {loading && <Loader />}

        <Box sx={{ float: 'right', marginTop: '30px' }}>
          <Typography variant="caption">{translate('auth.completeAccount.byProceeding')}</Typography>
          <Link
            component="button"
            variant="caption"
            onClick={(event) => {
              event.preventDefault();
            }}
            sx={{ textDecoration: 'underline', color: '#0066FF', marginLeft: '10px' }}
          >
            {translate('auth.completeAccount.termsCondition')}
          </Link>
        </Box>
      </form>
    </AuthBaseLayout>
  );
};

export default CompleteAccount;
