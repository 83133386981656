// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// hooks
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// components
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import BankAccountService from '../../../models/t-bank-accounts/services';
import * as userActions from '../../../models/user/actions';
import * as workspaceActions from '../../../models/workspace/actions';
import { selectors as workspaceSelectors } from '../../../models/workspace/reducers';
import AddBankAccountModal from './AddBankAccountModal';

// ----------------------------------------------------------------------

export default function Payments() {
  const dispatch = useDispatch();
  const { themeStretch } = useSettings();
  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const workspaceInformationData = useSelector(workspaceSelectors.workspaceInformationData);
  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);

  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');
  const activeMaktbSubscription = activeSubscriptions?.find((sub) => sub.solution === 'digital_office');
  const activePaySubscription = activeSubscriptions?.find((sub) => sub.solution === 'pay') || null;

  const columns = [
    {
      field: 'bank_name',
      headerName: translate('setting.paymentSetting.bank'),
      maxWidth: 200,
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.bank.name_ar : row.bank.name_en || '-'),
    },
    {
      field: 'Beneficiary Name',
      headerName: translate('setting.paymentSetting.beneficiaryName'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.beneficiary_name_ar : row.beneficiary_name_en || '-'),
    },
    {
      field: 'IBAN',
      headerName: translate('setting.paymentSetting.iban'),
      flex: true,
      headerClassName: 'datagrid-header',
      headerAlign: 'center',
      align: 'center',
      valueGetter: ({ row }) => (languageCode === 'ar' ? row.iban : row.iban || '-'),
    },
  ];

  useEffect(() => {
    dispatch(workspaceActions.getWorkspaceInformationRequest());
    dispatch(userActions.getUserInformationRequest());

    BankAccountService.getBankAccounts().then((res) => {
      setBankAccounts(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (!showAddBankAccountModal) {
      BankAccountService.getBankAccounts().then((res) => {
        setBankAccounts(res.data.data);
      });
    }
  }, [showAddBankAccountModal]);

  const callback = (type) => {
    setLoading(false);
    if (type === 'success') {
      toast(translate('setting.propertyRequest.successUpdate'), { type: 'success' });
      dispatch(workspaceActions.getWorkspaceInformationRequest());
      dispatch(userActions.getUserWorkspacesRequest());
      dispatch(userActions.getUserInformationRequest());
    } else {
      toast('Error!', { type: 'error' });
    }
  };

  const formik = useFormik({
    initialValues: {
      is_bank_transfer_payment_enabled: !!workspaceInformationData?.is_bank_transfer_payment_enabled,
      is_cash_payment_enabled: !!workspaceInformationData?.is_cash_payment_enabled,
      is_online_payment_enabled: !!workspaceInformationData?.is_online_payment_enabled,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(
        workspaceActions.editWorkspaceInformationRequest({
          ...values,
          callback,
        })
      );
    },
  });

  const handleSave = () => {
    setLoading(true);
    formik.handleSubmit();
  };

  return (
    <Page title={translate('setting.paymentSetting.title')}>
      <AddBankAccountModal open={showAddBankAccountModal} onClose={() => setShowAddBankAccountModal(false)} />

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h3" component="h1">
              {translate('setting.paymentSetting.title')}
            </Typography>
          </Box>
        </Box>

        <Card sx={{ mt: 3 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" paragraph>
                  {translate('setting.paymentSetting.paymentMethods')}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={translate('setting.paymentSetting.bankTransfer')}
                      control={
                        <Checkbox
                          name="is_bank_transfer_payment_enabled"
                          checked={formik.values.is_bank_transfer_payment_enabled}
                        />
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>

                {/* <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={translate('setting.paymentSetting.cash')}
                      control={
                        <Checkbox name="is_cash_payment_enabled" checked={formik.values.is_cash_payment_enabled} />
                      }
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid> */}

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={translate('setting.paymentSetting.onlinePayment')}
                      control={
                        <Checkbox
                          disabled={activePaySubscription === null}
                          name="is_online_payment_enabled"
                          checked={formik.values.is_online_payment_enabled}
                        />
                      }
                      onChange={formik.handleChange}
                    />
                    {activePaySubscription === null && (
                      <>
                        <span
                          style={{
                            backgroundColor: '#ddd',
                            marginLeft: 7,
                            marginRight: 7,
                            paddingRight: 12,
                            paddingLeft: 12,
                            paddingTop: 3,
                            paddingBottom: 3,
                            borderRadius: 5,
                          }}
                        >
                          {translate('setting.paymentSetting.requiresNuzulPay')}
                        </span>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent={'flex-end'}>
              <Button
                disabled={loading}
                variant="contained"
                sx={{ color: 'white', pt: 1, pb: 1 }}
                onClick={() => handleSave()}
              >
                {loading ? <CircularProgress size={20} /> : translate('setting.propertyRequest.saveButton')}
              </Button>
            </Grid>
          </CardContent>
        </Card>

        <Box sx={{ mt: 5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography variant="h3" component="h1">
                {translate('setting.paymentSetting.bankAccounts')}
              </Typography>
              <Button
                variant="contained"
                sx={{ color: 'white', pt: 1, pb: 1 }}
                onClick={() => setShowAddBankAccountModal(true)}
              >
                {translate('setting.paymentSetting.addBankAccount')}
              </Button>
            </Box>
          </Box>
          <Card sx={{ mt: 3 }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                  <Typography variant="h6" paragraph>
                    {translate('setting.paymentSetting.bankAccounts')}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <DataGrid
              // onRowClick={handleRow}
              columns={columns}
              rows={bankAccounts || []}
              disableColumnFilter
              disableColumnSelector
              // hideFooterSelectedRowCount
              disableSelectionOnClick
              rowsPerPageOptions={[10]}
              autoHeight
              pagination
              // page={queryParams.page - 1}
              // pageSize={queryParams.per_page}
              // rowCount={totalProjects || 0}
              // paginationMode="server"
              // onPageChange={handlePageChange}
              sx={{
                '& .datagrid-header': {
                  backgroundColor: '#F4F6F8',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderRadius: 0,
                },
                border: 'none',
              }}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
