import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Iconify from '../../../components/Iconify';
import { Loader } from '../../../components/Loader';
import Page from '../../../components/Page';
import PropertyCard from '../../../components/PropertyCard';
import { CLIENT_PORT, ENV } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import { selectors as subscriptionSelectors } from '../../../models/subscription/reducers';
import { selectors as userSelectors } from '../../../models/user/reducers';
import { TENANT_ROLES } from '../../../utils/constants';

const commonFieldsProps = {
  InputLabelProps: {
    shrink: true,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const calculateNights = (checkInDate, checkOutDate) => {
  const checkIn = new Date(checkInDate);
  const checkOut = new Date(checkOutDate);
  const diffTime = Math.abs(checkOut - checkIn);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const handlePaymentLink = (id, protocol, clientDomain) => {
  let url = '';
  if (ENV === 'local') {
    url = `${protocol}://${clientDomain}${CLIENT_PORT}/checkout/${id}`;
  } else {
    url = `${protocol}://${clientDomain}/checkout/${id}`;
  }
  window.location = url;
};

const ReservationsForm = ({ formik, filters, isLoading }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const { currentLang } = useLocales();
  const { themeStretch } = useSettings();

  const userInfo = useSelector(userSelectors.me);
  const workspace = useSelector(userSelectors.workspace);
  const activeSubscriptions = useSelector(subscriptionSelectors.activeSubscriptions);
  const activeSiyahaSubscription = activeSubscriptions?.find((sub) => sub.solution === 'siyaha');

  const totalNights = calculateNights(formik.values.check_in_date, formik.values.check_out_date);

  const protocol = ENV === 'local' ? 'http' : 'https';
  const clientDomain = workspace?.client_domain;

  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();

  const shouldShowGuestLink =
    formik.values.tenant_contact &&
    !activeSiyahaSubscription?.is_trial &&
    !(workspace?.role_id === TENANT_ROLES.companyMember && userInfo?.id !== formik.values.tenant_contact?.created_by);

  return (
    <Page title={translate('siyaha.reservation.reservationDetails')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box
          sx={{
            '& div.MuiBox-root': {
              p: 0,
            },
          }}
        >
          {isLoading && <Loader />}
          {!isLoading && (
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={11} lg={8}>
                <Grid container spacing={2}>
                  {/* dates */}
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                          {translate('siyaha.reservation.dates')}
                        </Typography>

                        <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                {...commonFieldsProps}
                                label={translate('siyaha.reservation.checkInDate')}
                                value={formik.values.check_in_date || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                {...commonFieldsProps}
                                label={translate('siyaha.reservation.checkOutDate')}
                                value={formik.values.check_out_date || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                {...commonFieldsProps}
                                name="purpose"
                                label={translate('siyaha.reservation.numberOfNights')}
                                value={totalNights || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* guest */}
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                          <Typography variant="h6" sx={{ mb: 0, mr: 1 }}>
                            {translate('guest.information')}
                          </Typography>

                          {shouldShowGuestLink && (
                            <Link href={`/siyaha/crm/guests/${formik.values.tenant_contact.id}`} underline="hover">
                              <Tooltip title={translate('contact.navigateToContact')}>
                                <IconButton variant="outlined" color="secondary">
                                  <Iconify icon="icon-park-outline:share" />
                                </IconButton>
                              </Tooltip>
                            </Link>
                          )}
                        </Box>

                        <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label={translate('siyaha.reservation.guestName')}
                                value={formik.values.tenant_contact?.name || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label={translate('siyaha.reservation.guestMobileNumber')}
                                value={formik.values.tenant_contact?.mobile_number || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                              <TextField
                                label={translate('siyaha.reservation.guestEmail')}
                                value={formik.values.tenant_contact?.email || ''}
                                disabled
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={11} lg={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" paragraph sx={{ mb: 1 }}>
                          {translate('siyaha.reservation.status')}
                        </Typography>

                        <Grid item xs={12} sm={12} md={12} sx={{ mb: 3 }}>
                          <Chip
                            sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                            label={translate(`siyaha.reservation.statues.${formik.values.status}`)}
                          />
                        </Grid>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Typography variant="h6" paragraph sx={{ mb: 1 }}>
                            {translate('siyaha.reservation.paymentStatus')}
                          </Typography>
                          <Chip
                            sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                            label={`${formik.values.order?.total_amount_with_tax} ${translate('SAR')}`}
                          />
                        </Box>

                        <Grid item xs={12} sm={12} md={12} sx={{ mb: 3 }}>
                          <Chip
                            sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                            label={translate(`siyaha.reservation.paymentStatuses.${formik.values.order?.status}`)}
                          />
                        </Grid>

                        <Typography variant="h6" paragraph sx={{ mb: 1 }}>
                          {translate('siyaha.reservation.paymentMethod')}
                        </Typography>

                        <Grid item xs={12} sm={12} md={12}>
                          {formik.values.order?.is_online_payment && (
                            <>
                              <Chip
                                sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                                label={translate(`siyaha.reservation.paymentMethods.online`)}
                              />
                              {formik.values.order?.status === 'pending_payment' && (
                                <Button
                                  variant="text"
                                  onClick={() => {
                                    handlePaymentLink(formik.values.order?.uuid, protocol, clientDomain);
                                  }}
                                >
                                  Payment Link
                                </Button>
                              )}
                            </>
                          )}
                          {formik.values.order?.is_bank_transfer && (
                            <Chip
                              sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                              label={translate(`siyaha.reservation.paymentMethods.bankTransfer`)}
                            />
                          )}
                          {formik.values.order?.is_cash && (
                            <Chip
                              sx={{ backgroundColor: '#FFC10722', color: '#7A4F01' }}
                              label={translate(`siyaha.reservation.paymentMethods.cash`)}
                            />
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6" paragraph sx={{ mb: 2 }}>
                          {translate('siyaha.reservation.propertyInformation')}
                        </Typography>
                        {formik.values.property && <PropertyCard property={formik.values.property} />}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ReservationsForm;
