// @mui
import { Card, Container, Grid, Typography } from '@mui/material';
// hooks
import { useNavigate } from 'react-router-dom';

// components
import Page from '../../components/Page';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

export default function PageOne() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const navigate = useNavigate();

  const selectedProduct = localStorage.getItem('selected_product');

  return (
    <Page title={translate('settings')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h3" component="h1" paragraph>
          {translate('settings')}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                mt: 2,
                py: 2,
                textAlign: 'start',
                px: 4,
                cursor: 'pointer',
                borderRadius: '7px',
                border: 'solid rgba(255, 255, 255, 0.0)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.4)', // Example hover background color
                  border: 'solid white',
                },
              }}
              onClick={() => {
                navigate('account');
              }}
            >
              <Typography variant="subtitle2" color={'secondary'}>
                {translate('setting.accountInformationTitle')}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.64 }}>
                {translate('setting.accountInformation')}
              </Typography>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                mt: 2,
                py: 2,
                textAlign: 'start',
                px: 4,
                cursor: 'pointer',
                borderRadius: '7px',
                border: 'solid rgba(255, 255, 255, 0.0)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.4)', // Example hover background color
                  border: 'solid white',
                },
              }}
              onClick={() => {
                navigate('payments');
              }}
            >
              <Typography variant="subtitle2" color={'secondary'}>
                {translate('setting.paymentSettingsTitle')}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.64 }}>
                {translate('setting.paymentSettings')}
              </Typography>
            </Card>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                mt: 2,
                py: 2,
                textAlign: 'start',
                px: 4,
                cursor: 'pointer',
                borderRadius: '7px',
                border: 'solid rgba(255, 255, 255, 0.0)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.4)', // Example hover background color
                  border: 'solid white',
                },
              }}
              onClick={() => {
                navigate('subscriptions');
              }}
            >
              <Typography variant="subtitle2" color={'secondary'}>
                {translate('setting.accountSubscriptionTitle')}
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.64 }}>
                {translate('setting.accountSubscription')}
              </Typography>
            </Card>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
